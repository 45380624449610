.body {
  overflow: hidden;
  height: auto!important;
}
.breadcrumb {
  display: flex;
  align-items: baseline;
}
.body .button-full {
  margin-bottom: 30px;
}


.illustrationWrapper {
  position:relative;
  margin:0 auto;
  margin-bottom: 20px;
  height: 60vh;
  overflow:hidden;
  width: fit-content;
}
 
.body--outline {
  height: 100%;
  display: block;
  margin: 0 auto;
  z-index: 99999;
  pointer-events: none;
}
#light .body--outline {
  fill: #005c73!important;
  stroke: #005c73!important;
  stroke-width:2px;
}
#dark .body--outline {
  fill: #9fb4bd!important;
  stroke: #9fb4bd!important;
  stroke-width:2px;
}
.body--frame {
  width: calc(100vw - 40px);
  height: 100%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
#light .body--frame {
  background-image: url("../illustrations/bodyOutline.png");
}
#dark .body--frame {
  background-image: url("../illustrations/bodyOutline-darkmode.png");
  opacity: 0.5;
}
 


.selectionWrapper {
  /* position:absolute; */
  /* width:100%; */
  /* height:100%; */
  /* top:0; */
  /* opacity: .4; */
  /* display: flex;
  justify-content: center; */
}
.selectionWrapper svg, .selectionWrapper img {
  position:absolute;
  width: auto;
  max-width:fit-content;
  display: block;
  left:0;
  right:0;
  margin-left:auto;
  margin-right:auto;
  opacity: 0.7;
  /* background-color:yellow; */
}
.selectionWrapper .nonSelectedPart {
  opacity: 0.2;
}
.selectionWrapper .selectedPart {
  opacity: 0.8;
}


.body--head {
  max-height: 19.5%;
  top: 0;
}

.body--torso {
  max-height: 34.8%;
  top: 18.2%;
  z-index: 9;
}

.body--arms {
  max-height:39.4%;
  top: 18.5%;
  z-index: 8;
}
.body--legs {
  max-height:50.5%;
  bottom: 0;
}
