.QRcode {
  padding: 10px 20px;
  max-width: fit-content;
  margin: 0 auto;
  margin-top: 15px;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
#light .QRcode {
  background-color: #fff;
  border: 1px solid #ddd;
}
#dark .QRcode {
  background-color: #000;
  border: 1px solid #666;
}
.QRcode p {
  text-decoration: none !important;
  font-weight: bold;
  line-height: 1.2;
  padding-bottom: 10px;
  margin-right: 10px;
}
.QRcode img {
  max-width: 80px;
}
