#desktopSideNav {
  width: 300px;
  padding-top: 15px;
}
#light .sideNavActive {
  background-color: #ededed;
  color: #9a6413;
}
#dark .sideNavActive {
  background-color: #212628;
  color: #e7b06d;
}
.sideNavActive > p::after {
  content: ">";
}
.desktopSideNav--item {
  padding: 13px 20px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
#light .desktopSideNav--item {
  border-bottom: 1px solid #ccc;
}
#dark .desktopSideNav--item {
  border-bottom: 1px solid #666;
}
.desktopSideNav--item:last-child {
  border: 0;
}
.desktopSideNav--item svg {
  opacity: 0.4;
}
.desktopSideNav--item p {
  padding-left: 10px;
  font-size: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

/* Nested navigation */
#light .sideSubNavWrapper {
  border-left: 10px solid #ccc;
}
#dark .sideSubNavWrapper {
  border-left: 10px solid #666;
}
.sideSubNavWrapper .desktopSideNav--item {
  padding-top: 10px;
  padding-bottom: 10px;
}
.sideSubNavWrapper .desktopSideNav--item p {
  font-size: 1.1rem;
}
