/* GLOBAL STYLE */
* {
  margin: 0;
  padding: 0;
}
#darkModeToggle {
  position: absolute;
  right: 20px;
  top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
#darkModeToggle svg {
  opacity: 0.3;
}
#light {
  background-color: #f7f7f7;
  color: #005c73;
}
#dark {
  background-color: #212628;
  color: #efefef;
}
a {
  color: inherit;
}
p {
  line-height: 1.4;
}
li {
  line-height: 1.2;
}
img {
  max-width: 600px;
}

/* Typography */
.smallText {
  /* font-size: 11px; */
}

/* Asset styles */
.button-full,
.button-full-secondary {
  padding: 15px 0;
  font-weight: bolder;
  margin: 0 10px;
  text-align: center;
  border-radius: 4px;
  text-decoration: none;
  display: block;
  width: calc(100% - 20px);
}
#light .button-full {
  background-color: #005c73;
  color: #fff;
}
#dark .button-full {
  background-color: #0c6c82;
  color: #efefef;
}
.button-disabled {
  opacity: 0.4;
}
.button-underlined {
  text-decoration: underline;
}

#light .button-full-secondary {
  border: 1px solid #005c73;
  color: #013d4c;
}
#dark .button-full-secondary {
  border: 1px solid #05e6e1;
  color: #fff;
}

.float-bottom {
  position: fixed;
  bottom: 85px;
  width: calc(100vw - 100px);
}

.App {
  min-height: calc(100vh - 75px);
}

/* Header */
.header {
  top: 0;
  left: 0;
  width: 100%;
}
.header .logo {
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0;
  padding: 20px 20px 10px 20px;
  text-align: left;
  opacity: 0.4;
  line-height: 25px;
}

/*  */
/*  */
/* Desktop style only for side navigation */
.desktopStyleOnly {
  display: flex;
  flex-direction: row-reverse;
}
.desktopStyleOnly .button-full {
  max-width: 300px;
  margin: 0 auto;
}
.desktopStyleOnly .content {
  width: calc(100% - 300px);
  min-height: calc(100vh - 40px);
}
.desktopStyleOnly .listWrapper-box {
  left: auto;
  right: auto;
  width: auto;
}

.sideNavWrapper {
  min-height: 100vh;
  position: fixed;
  left: 0;
  background-color: #fff;
}
#light .sideNavWrapper {
  border-right: 1px solid #005c73;
  background-color: #fff;
}
#dark .sideNavWrapper {
  border-right: 1px solid #0c6c82;
  background-color: #111;
}
.sideNavWrapper #darkModeToggle {
  position: unset;
  align-items: flex-start;
  padding: 20px;
  flex-direction: inherit;
  opacity: 0.7;
}
.sideNavWrapper #darkModeToggle::after {
  content: "Dark Mode ON/OFF";
  padding-left: 5px;
}

.desktopStyleOnly .listSearch {
  padding-top: 60px;
}
.desktopStyleOnly .listWrapper-box {
  height: 100vh;
}
.desktopStyleOnly .listWrap {
  padding-bottom: 0;
}
.desktopStyleOnly .listWrapper-box {
  width: calc(100% - 300px);
  max-width: 850px;
}

/* Content size and top padding */
.desktopStyleOnly .content > * {
  max-width: 850px;
  margin: 0 auto;
}
.desktopStyleOnly .bodyContentList,
.desktopStyleOnly .contentDetail {
  margin-top: 50px;
}
.desktopStyleOnly .basicScreen {
  margin-top: 70px;
}
.desktopStyleOnly .bodyContentList-back,
.desktopStyleOnly .contentDetailContainer-back {
  margin-bottom: 10px;
  text-align: left;
}

/* Cursor pointer adjustment */
.desktopStyleOnly .content .accordion-title:hover {
  cursor: pointer;
}

@media only screen and (max-width: 680px) {
  .App {
  }
}
