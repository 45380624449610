#mobileBottomNav {
  padding:0 16px 15px 16px;
  position:fixed;
  bottom: 0;
  width: calc(100% - 32px);
  height: fit-content;
  z-index: 99999;
  text-align:center;
}
#dark #mobileBottomNav {
  border-top: 1px solid #666;
  background-color: #212628;
}
#light #mobileBottomNav {
  border-top: 1px solid #ccc;
  background-color: #f7f7f7;
}
.mobileBottomNav--wrapper {
  display: flex;
  justify-content: space-around;
}

.mobileBottomNav--item {
  padding-top: 13px;
  text-decoration: none;
  color: inherit;
}


/* Active style */
.bottomNavActive {
  margin-top:-2px;
  padding-top: 11px;
}
.bottomNavInactive {
  margin-top: 0;
  padding-top: 13px;
}
#light .bottomNavActive {
  border-top: 4px solid #212628;
  color:#212628;
}
#dark .bottomNavActive {
  border-top: 4px solid #efefef;
  color:#efefef;
}
#light .bottomNavInactive {
  border-top: none;
  color:#748e9b;
}
#dark .bottomNavInactive {
  border-top: none;
  color: #748e9b;
}


/* 604a2a */