.nonMobile {
  display:flex;
  justify-content: center;
  align-items: center;
  height: 75vh;
  min-height: 100vh;
}
.nonMobile__content {
  text-align:center;
  padding: 30px;
}
.nonMobile__content-qr{
  width:70%;
  min-width:100px;
  max-width: 200px;
  display:block;
  margin:0 auto;
}
.nonMobile_logo {
  font-size: 12px;
  font-weight: 800;
  text-transform: uppercase;
  letter-spacing: 0;
  padding: 20px 20px 10px 20px;
  text-align: center;
  opacity: 0.4;
  line-height: 25px;
}