.buttonView {
  /* overflow: hidden; */
  height: auto !important;
  padding-bottom: 100px;
}
.landingGuide {
  padding: 10px;
  text-align: center;
  opacity: 0.7;
}
.buttonWrapper {
  position: relative;
  margin: 0 auto;
  height: 73vh;
  overflow: hidden;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.button {
  width: calc(100% - 20px);
  margin: 7px auto;
  display: flex;
  height: 80px;
  align-items: center;
  border-radius: 4px;
  text-decoration: none;
  overflow: hidden;
}
#light .color-intro {
  background-color: #c5a89e;
  color: #231f20;
}
#light .color-headneck {
  background-color: #ce322f;
  color: #efefef;
}
#light .color-torso {
  background-color: #fec761;
  color: #231f20;
}
#light .color-upper {
  background-color: #005c73;
  color: #efefef;
}
#light .color-lower {
  background-color: #5f1f15;
  color: #efefef;
}
#light .color-other {
  background-color: #7b8b99;
  color: #efefef;
}

#dark .color-intro {
  background-color: #8c7a74;
  color: #efefef;
}
#dark .color-headneck {
  background-color: #992b29;
  color: #efefef;
}
#dark .color-torso {
  background-color: #aa833c;
  color: #efefef;
}
#dark .color-upper {
  background-color: #0c6c82;
  color: #efefef;
}
#dark .color-lower {
  background-color: #67443f;
  color: #efefef;
}
#dark .color-other {
  background-color: #7b8b99;
  color: #efefef;
}

.button .buttonView_thumbWrapper {
  width: 50px;
  text-align: center;
  height: calc(100% - 10px);
  line-height: 75px;
  padding: 5px;
}
.buttonView_thumbWrapper {
  width: 100%;
  height: 100%;
}
.button .buttonView_thumbWrapper img {
  max-width: 100%;
  max-height: 100%;
}
.button .buttonView_text {
  padding: 0 5px 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 70px);
}
.button .buttonView_text h2 {
  text-decoration: none;
}
.button .buttonView_text svg {
  opacity: 0.4;
}

.buttonView_thumb {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 100%;
}
.buttonView_thumb--headneck {
  background-image: url("../illustrations/mainButton-headneck.png");
}

.buttonView_thumb--torso {
  background-image: url("../illustrations/mainButton-torso.png");
}

.buttonView_thumb--upper {
  background-image: url("../illustrations/mainButton-arm.png");
}

.buttonView_thumb--lower {
  background-image: url("../illustrations/mainButton-legs.png");
}

@media only screen and (max-width: 680px) {
  .buttonWrapper {
    height: auto;
  }
  .button {
    margin: 4px auto;
    height: 60px;
  }
  .button .buttonView_text h2 {
    font-size: 24px;
    line-height: 1;
  }
}
