.contentDetail {
  display: block;
}
.contentDetailContainer {
  padding-bottom: 100px;
}
.contentDetailContainer h1 {
  margin-bottom:30px;
}
.contentDetailContainer-back{
  margin-top:15px;
  display:block;
  text-align:center;
}
.contentDetailContainer-title {
  text-align:center;
}
.contentDetail--section-content {
  word-break: break-word;
}
.contentDetail--section-content img {
  display: block;
  width: 100%;
}