/* Content */
.content {
  position: relative;
  overflow: hidden;
  padding-bottom: 40px;
}
.content > * {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  overflow: auto;
  padding-left: 20px;
  padding-right: 20px;
}
.content--section {
  margin-bottom: 20px;
}
.content--section h1,
.content--section h2,
.content--section h3 {
  margin-bottom: 5px;
}
.content--section ol,
.content--section ul {
  padding-left: 35px;
}
.content--section li {
  margin-bottom: 5px;
}

@media only screen and (max-width: 680px) {
  .content {
    padding-bottom: 0px;
  }
}
